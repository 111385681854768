/* .usernameBox {
  display: flex;
  background: #060ce9;
  height: 100px;
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  top: calc(50vh - 100px);
  left: calc(50vw - 185px);
  transition: all 1s;
  width: 0px;
  overflow: hidden;
  padding: 0px;
}

.usernameBox button {
  background: transparent;
  color: white;
  font-size: 20px;
  font-weight: bold;
  width: 60px;
  border: solid white 2px;
  height: 40px;
  cursor: pointer;
}

.usernameBox button:hover{
  background:white;
  color:#060ce9;
}

#usernameInput {
  background: transparent;
  color: white;
  border: solid white 2px;
  padding: 5px;
  font-size: 18px;
  height: 40px;
  box-sizing: border-box;
  width: 240px;
}

.fullInnerWrapper {
  width: 800px;
  height: 800px;
  border: solid 20px #060ce9;
  border-radius: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.fullWrapper {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.topMenu {
  padding: 15px;
  border-bottom: 5px solid #060ce9;
  display: flex;
}

.topMenu span {
  color: #060ce9;
  font-size: 20px;
  font-weight: bold;
  flex-grow: 100;
}

.topMenu .editButton {
  background: white;
  border: none;
  font-size: 30px;
  line-height: 0px;
  transform: rotate(135deg);
  color: #060ce9;
  padding: 0px 0px 5px 5px;
  transition: all 1s;
  border-radius: 100px;
}

.topMenu .editButton:hover {
  color: black;
  cursor: pointer;
}

.topMenu .logOutButton {
  border: solid 3px #060ce9;
  background: transparent;
  color: #060ce9;
  border-radius: 5px;
  transition: all 1s;
}

.topMenu .logOutButton:hover {
  background: #060ce9;
  color: white;
  cursor: pointer;
}

#searchCreateInput {
  flex-grow: 100;
  border: solid #060ce9 3px;
  border-top: none;
  padding: 10px;
  font-size: 17px;
}

.roomListMenu {
  display: flex;
  height: 50px;
}

.roomListMenu button {
  background: transparent;
  border: none;
  border-bottom: solid #060ce9 3px;
  font-size: 17px;
  color: #060ce9;
  cursor: pointer;
}

.roomList {
  flex-grow: 100;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

.roomList>li {
  list-style: none;
}

.roomList>li>a {
  display: flex;
  font-size: 15px;
  padding: 0px 15px;
  text-decoration: none;
}

.roomList>li>a:not(:last-child) {
  border-bottom: 3px solid #060ce9;
}

.roomList>li>a h1 {
  font-size: 20px;
  color: #060ce9;
}

.roomList>li>a .roomListName {
  flex-grow: 100;
}

.boardWrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  height: 80%;
}

.readyWrapper {
  position: absolute;
  background: #060ce9;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50vh - 100px);
  left: calc(50vw - 100px);
  border-radius: 10px;
  border: solid white 3px;
}

.readyWrapper button {
  background: transparent;
  color: white;
  font-size: 20px;
  width: 100px;
  height: 100px;
  font-weight: bold;
  border: solid white 3px;
  border-radius: 5px;
  transition: all 1s;
  cursor: pointer;
}

.readyWrapper button:hover {
  border-color: yellow;
}

.boardColumn {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: 20% 16% 16% 16% 16% 16%;
}

.boardColumn li {
  list-style: none;
  margin: 5px;
  background: #060ce9;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  font-weight: bolder;
  padding: 10px;
}

.boardColumnScreen {
  font-size: 30px;
}

.playerBarWrapper {
  display: grid;
  grid-template-columns: 33% 33% 33%;
  flex-grow: 100;
}

.playerBarWrapper h1, .playerBarWrapper h2 {
  margin: 0;
  height: 50%;
}

.buzzButton {
  width: 200px;
  height: 100px;
  cursor: pointer;
  background: transparent;
  border: solid white 3px;
  color: white;
  font-size: 22px;
  transition: all 0.5s
}

.buzzButton:hover {
  background: white;
  color: #060ce9
}

.largeScreenWrapper {
  position: absolute;
  background: #060ce9;
  width: 740px;
  height: 588px;
  margin: 10px;
  border-radius: 10px;
}

.largeScreenWrapper .largeScreenInnerWrapper {
  display: flex;
  flex-direction: column;
  width: 740px;
  height: 588px;
  padding: 20px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}

.largeScreenWrapper .largeScreenInnerWrapper span {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin: 30px 0px;
}

.largeScreenInnerWrapper input {
  width: 400px;
} */

.topMenu {
  background: #0387E5;
  box-shadow: 0px 5px 5px #666;
  height: 60px;
}

#dropdownWrapper {
  font-size: 30px;
  padding: 10px 20px;
  width: 30px;
}

.dropdownMenu {
  position: absolute;
  list-style: none;
  background: #0387E5;
  margin: 0;
  box-sizing: border-box;
  margin-top: 9px;
  box-shadow: 5px 5px 5px #666;
  padding: 0;
  opacity: 0;
  transition: all 0.5s;
}

#dropdownWrapper:hover .dropdownMenu {
  opacity: 1;
}

#dropdownWrapper .dropdownMenu li {
  display: none;
  transition-delay: 0.5s;
  transition: all 0s;
}

#dropdownWrapper:hover .dropdownMenu li {
  display: flex;
}

.dropdownMenu li:nth-child(1) {
  padding: 10px;
  border-bottom: solid black 1px;
}

.dropdownMenu li:nth-child(1) span {
  font-size: 18px;
  font-weight: bold;
  color: white;
}

.dropdownMenu li:nth-child(1) button {
  background: transparent;
  border: none;
  font-size: 30px;
  line-height: 0px;
  transform: rotate(135deg);
  color: black;
  padding: 0px 0px 5px 5px;
  transition: all 1s;
  border-radius: 100px;
  cursor: pointer;
}

.dropdownMenu li:nth-child(2) {
  display: flex;
}

.logOutButton {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: all 0.5s;
}

.logOutButton:hover {
  background: #025FA0;
}

.usernameBox {
  display: flex;
  background: #0387E5;
  height: 150px;
  box-sizing: border-box;
  border-radius: 10px;
  position: absolute;
  top: calc(50vh - 75px);
  left: calc(50vw - 185px);
  transition: all 1s;
  width: 0px;
  overflow: hidden;
  padding: 0px;
  font-size: 18px;
  flex-direction: column;
  box-shadow: 5px 5px 5px #666;
  z-index: 2;
}

.usernameBox h1 {
  flex-basis: 50%;
  margin: 0;
  color: white;
  font-size: 22px;
}

#usernameInput {
  border: solid white 2px;
  background: transparent;
  color: white;
  font-size: 18px;
  box-sizing: border-box;
  width: 240px;
}

.usernameBox>div {
  display: flex;
  flex-direction: row;
}

.usernameBox>div button {
  flex-basis: 15%;
  background: transparent;
  border: solid white 2px;
  color: white;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background 0.5s;
}

.usernameBox>div button:hover {
  background: #025FA0
}

.usernameBackground {
  z-index: 1;
  background: #0004;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.notMenu {
  max-width: 1111px;
  margin: auto;
  width: 90vw;
  margin-top: 20px;
}

.roomListMenu {
  display: flex;
}

#searchCreateInput {
  flex-basis: 100%;
  border: none;
  border-top: solid #ccc 3px;
  border-bottom: solid #ccc 3px;
  font-size: 18px;
  padding: 0px 10px;
  box-sizing: border-box;
}

.refreshButton {
  font-size: 30px;
  background: transparent;
  border: solid #ccc 3px;
  border-radius: 5px 0px 0px 5px;
  height: 45px;
  line-height: 0px;
  cursor: pointer;
  transition: all 0.5s;
}

.roomListMenu button:last-child {
  flex-basis: 200px;
  background: transparent;
  font-size: 18px;
  border: solid #ccc 3px;
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  transition: all 0.5s;
}

.refreshButton:hover, .roomListMenu button:last-child:hover {
  background: #ccc
}

.roomList {
  list-style: none;
  padding: 10px;
}

.roomList a {
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
}

.roomList a h1:first-child {
  flex-basis: 90%;
}

.roomList a h1:last-child {
  font-size: 20px;
  flex-basis: 10%;
}

.roomList a h1 {
  margin: 0;
}

.roomList>li {
  margin: 10px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px #666;
  background: #0387E5;
}

.inRoom {
  max-width: 800px;
  width: 90vw;
  margin: 20px auto auto;
  display: flex;
  flex-direction: column;
}

.readyWrapper {
  position: absolute;
  background: white;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(50vh - 100px);
  left: calc(50vw - 100px);
  border-radius: 10px;
  border: solid #0387E5 3px;
  z-index: 100;
}

.readyWrapper button {
  background: transparent;
  color: #0387E5;
  font-size: 20px;
  width: 100px;
  height: 100px;
  font-weight: bold;
  border: solid #0387E5 3px;
  border-radius: 5px;
  transition: all 1s;
  cursor: pointer;
}

.readyWrapper button:hover {
  border-color: transparent;
}

.boardWrapper {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  min-height: 80vmin;
}

.boardColumn {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: 20% 16% 16% 16% 16% 16%;
}

.boardColumn li {
  list-style: none;
  margin: 5px;
  background: #060ce9;
  color: #ecd210;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  font-weight: bolder;
  padding: 10px;
  overflow: hidden;
}

.boardColumnTitle {
  text-transform: capitalize;
}

.boardColumnScreen {
  font-size: 30px;
}

.playerBarWrapper {
  display: grid;
  grid-template-columns: 33% 33% 34%;
  width: 90vw;
  bottom: 0px;
  height: 100px;
  max-width: 800px;
  background: #0387E5;
}

.playerBarWrapper h1, .playerBarWrapper h2 {
  margin: 0;
  height: 50%;
}

.playerBarWrapper>div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  height: 100px;
  transition: all 1s;
  color: white;
  overflow: hidden;
}

.playerTwo {
  border-left: solid white 3px;
  border-right: solid white 3px;
}

.largeScreenWrapper {
  position: absolute;
  background: #060ce9;
  border-radius: 10px;
  overflow: hidden;
  /* max-width: 800px;
  width: 90vw;
  height: calc( 100vh - 80px - 110px);
  min-height: 500px; */
}

.largeScreenWrapper .largeScreenInnerWrapper {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  max-width: 800px;
  width: 90vw;
  height: calc( 100vh - 80px - 110px);
  min-height: 500px;
}

.largeScreenWrapper .largeScreenInnerWrapper span {
  color: white;
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin: 30px 0px;
}

.largeScreenInnerWrapper div:first-child {
  display: flex;
  flex-basis: 60%;
  align-items: flex-end;
  padding: 0px 30px;
}

.largeScreenInnerWrapper input {
  width: 400px;
}

.largeScreenInnerWrapper button {
  background: transparent;
  color: white;
  font-size: 20px;
  border: solid white 3px;
  padding: 10px 30px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 10px;
  transition: all 0.5s;
  cursor: pointer;
}

.largeScreenInnerWrapper button:hover {
  background: #999;
}

@media only screen and (max-width: 500px) {
  .inRoom .boardColumnTitle {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1000px) {
  .largeScreenWrapper, .largeScreenWrapper .largeScreenInnerWrapper {
    width: 100vw !important;
    height: 100vh !important;
    top: 0px !important;
    left: 0px !important;
    min-height: 0px !important;
    position: fixed;
  }
  .boardColumnTitle:hover, .boardColumnTitle:focus, .boardColumnTitle:active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50vh;
    font-size: 30px;
  }
}